// @ts-ignore
const buildTarget = process.env.REACT_APP_BUILD_TARGET;

switch (buildTarget) {
    case 'admin':
        import('./admin/app');
        break;
    case 'play':
    default:
        import('./play/app');
        break;
}

